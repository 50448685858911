const cart = {
    state: {
        cart: null
    },

    mutations: {
        cartUpdate: function (state, payload) {
            state.cart = payload;
        },
    },

    actions: {
        updateCart: function (context) {
            window.api.get('catalog/basket')
                .then(response => {
                    context.commit('cartUpdate', response.data.data || [])
                })
                .catch(() => {
                    context.commit('cartUpdate', null)
                })
        },
    },

    getters: {
        getCart: function (state) {
            return state.cart;
        },
        getProductsCount(state){
            if(state.cart!==null && state.cart.length){
                return state.cart.map(item => item.amount).reduce((prev, next) => prev + next);
            } else {
                return 0;
            }
        },
        getCartValue(state){
            if(state.cart!==null){
                let total = 0;

                // Iteracja przez produkty w odpowiedzi
                state.cart.forEach(item => {
                    const price = item.product.price;
                    const amount = item.amount;

                    // Dodanie wartości produktu do sumy
                    total += price * amount;
                });

                return total;
            } else {
                return 0;
            }
        }
    }
}

export default cart;
